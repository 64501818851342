import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import Blog1 from "../images/blog-1.jpg"
import Blog2 from "../images/blog-2.jpg"
import Blog3 from "../images/blog-3.png"
import Blog4 from "../images/blog-4.png"
import Blog5 from "../images/blog-5.png"
import Blog6 from "../images/blog-6.png"
import Blog7 from "../images/blog-7.jpg"
import Blog8 from "../images/blog-8.jpg"
import Blog9 from "../images/blog-9.jpg"
import Blog10 from "../images/blog-10.jpg"
import Blog11 from "../images/blog-11.jpg"
import Blog12 from "../images/blog-12.jpg"
import Blog13 from "../images/blog-13.png"
import Blog15 from "../images/blog-15-image2.png"
import Blog16 from "../images/blog-16-image001.png"
import Blog17 from "../images/blog-17-1.png"
import Blog18 from "../images/blog18.png"
import Blog19 from "../images/blog19.png"
import Blog20 from "../images/blog20.png"
import Blog21 from "../images/blog21/image6.jpg"
import Blog22 from "../images/blog22/image1.png"
import Blog23 from "../images/blog23/image9.jpg"
import Blog24 from "../images/blog24/image1.png"
import Blog25 from "../images/blog25/image1.png"
import Blog26 from "../images/blog26/image2.png"
import Blog27 from "../images/blag27/image1.jpg"
import Blog29 from "../images/blog29/image2.jpg"
import Blog30 from "../images/blog30/image8.jpg"
import Blog31 from "../images/blog31/image1.jpg"
import Blog32 from "../images/blog32/image1.png"
import Blog33 from "../images/blog33/image3.jpg"
import Blog34 from "../images/blog34/image1.png"
import Blog35 from "../images/blog35/image1.jpg"
import Blog36 from "../images/blog37/image5.jpg"
import Blog38 from "../images/blog38/image4.png"
import Blog39 from "../images/blog39/image10.png"
import Blog40 from "../images/blog40/image7.png"
import Blog41 from "../images/blog41/image2.png"
import Link from "gatsby-link";
import {Helmet} from "react-helmet";
import {Component} from "react";
import { useState } from 'react';

function Article(props){
        const FinalPreview = props.previewText.substring(0, 250) + "...";
    return(
        <article className="flex flex-col shadow my-4">

            <Link to={props.href} className="hover:opacity-75">
                <img className={"w-full max-h-[500px] object-cover object-top " + props.InStyle} alt={"blog image"} src={props.src}/>
            </Link>
            <div className="bg-white flex flex-col justify-start p-6">
                <Link to={props.href} className="text-3xl font-bold hover:text-gray-700 pb-4">{props.title}</Link>
                <p className="text-sm pb-3">
                    By <Link to="#" className="font-semibold hover:text-gray-800">{props.author}</Link>, Published on {props.date}
                </p>
                <Link to={props.href} className="pb-6">{FinalPreview}</Link>
                <Link to={props.href} className="uppercase text-gray-800 hover:text-black">Continue Reading <i
                    className="fas fa-arrow-right"></i></Link>
            </div>
        </article>
    )

}
let articles = [
    <Article
    key={"blog41"}
    href={"knee-pain-should-not-keep-you-from-running"}
    src={Blog41}
    title={"Knee Pain Should NOT Keep You from Running"}
    author={"Dr. Donald Mull, DC"}
    date={"Jun. 13, 2024"}
    previewText={"Have you been told you need to ditch running because of your knee pain? What if I told you that you should not listen to this advice? You’re probably thinking, “sure that sounds nice but every time I run, it hurts and I have tried everything”."}
    InStyle={"object-bottom"}
/>,
    <Article
        key={"blog40"}
        href={"ACL-injuries-part-three"}
        src={Blog40}
        title={"ACL Injuries Part 3: What ACL Rehab Should Look Like"}
        author={"Dr. Donald Mull, DC"}
        date={"Nov. 9, 2023"}
        previewText={"Here we are. We can finally talk about what your ACL rehab should really look like. The previous blogs we covered the trends of ACL injuries and how it affects young athletes the most as well as the number one thing you or your athletes will want to do when an ACL injury occurs. "}
    />,
    <Article
        key={"blog39"}
        href={"ACL-injuries-part-two"}
        src={Blog39}
        title={"ACL Injuries Part 2: Priority #1 on Day 1"}
        author={"Dr. Donald Mull, DC"}
        date={"Oct. 30, 2023"}
        previewText={"In the first part of this blog on ACL injuries, we talked about how ACL injuries have been on the rise and the unfortunate truth is that our youth athletes are the ones affected most. We also unveiled how reinjury is all too common, one in five athletes experiencing a reinjury upon returning to high-risk sports after an ACL reconstruction."}
    />,
    <Article
        key={"blog38"}
        href={"ACL-injuries-part-one"}
        src={Blog38}
        title={"ACL Injuries Part 1: We Have Been Trending in the Wrong Direction"}
        author={"Dr. Donald Mull, DC"}
        date={"Oct. 23, 2023"}
        previewText={"We want to share what we think puts an athlete in the best position to come back safely, with as little risk as possible for re-injury. Based on the stats you will want to do whatever it takes to put the odds in your favor, trust me."}
    />,
    <Article
        key={"blog37"}
        href={"top-3-reasons-why-rehab-fails"}
        src={Blog36}
        title={"Top 3 Reasons Why Rehab Fails"}
        author={"Dr. Donald Mull, DC"}
        date={"July. 24, 2023"}
        previewText={"Not all physical therapy and/or chiropractic clinics are created equally. A large number of the people we help end up in our office having tried anything and everything for their issues. In fact this is the most common story we hear."}
    />,
    <Article
        key={"blog36"}
        href={"kettlebell-basics-how-to-get-started"}
        src={Blog35}
        title={"Kettlebell Basics: How to Get Started"}
        author={"Dr. Ben DeLuca, DC"}
        date={"Jun. 12, 2023"}
        previewText={"When starting a Kettlebell program anticipate a learning curve. Think of using a kettlebell like learning martial arts, each progression like moving up in a belt. Using this analogy, a deadlift can be considered a white belt, a blue belt the two handed swing, and a black belt the double bell snatch. \n"}
    />,
    <Article
        key={"blog35"}
        href={"all-things-kettlebell-a-brief-intro"}
        src={Blog35}
        title={"All Things Kettlebell: A Brief Intro"}
        author={"Dr. Ben DeLuca, DC"}
        date={"Jun. 6, 2023"}
        previewText={"Kettlebells (KB) entered the US market in the 90’s and were made popular by Pavel Tsatsouline a former Soviet Special Forces trainer who migrated from Belarus. Because it is simply a weight with a handle on it the origins can be traced back to tools used on farms and boats hundreds of years earlier.  \n"}
    />,
    <Article
        key={"blog34"}
        href={"how-to-increase-club-head-speed"}
        src={Blog34}
        title={"How to Increase Club Head Speed"}
        author={"Dr. Donald Mull, DC"}
        date={"Apr. 25, 2023"}
        previewText={"Let’s be honest, we all dig the long ball. It’s no wonder why club head speed has become the talk of the clubhouse, especially the past decade with how accessible it has become to measure these things."}
    />,
    <Article
        key={"blog33"}
        href={"what-causes-low-back-pain"}
        src={Blog33}
        title={"What Causes Low Back Pain?"}
        author={"Dr. Donald Mull, DC"}
        date={"Mar. 22, 2023"}
        previewText={"I couldn’t tell you how many times I have gotten this question throughout the span of my professional career. It is literally countless. This also tells me how far we are from addressing one of the most costly ailments in our society. The fact that I get this question so often, leads me to believe how misinformed the society is when it comes to lower back pain."}
    />,
    <Article
        key={"blog32"}
        href={"why-your-back-pain-should-not-stop-you-from-being-physically-active"}
        src={Blog32}
        title={"Why Your Back Pain Should NOT Stop You From Being Physically Active"}
        author={"Dr. Donald Mull, DC"}
        date={"Mar. 15, 2023"}
        previewText={"When someone gets hurt or feels pain, what should they do? Should you rest when your back hurts? Does massage work for back pain? What exercises are good for back pain? Because I am a chiropractor and strength coach I can’t tell you how many times I have heard these questions. If I had a nickel for every time I hear these at every single family outings or when I meet someone at a party I would be a rich man in the 1920s."}
    />,
    <Article
        key={"blog31"}
        href={"everything-you-want-is-on-the-other-side-of-hard"}
        src={Blog31}
        title={"Everything You Want is on the Other Side of Hard"}
        author={"Dr. Donald Mull, DC"}
        date={"Feb. 28, 2023"}
        previewText={"We are sold “easy” way too often as a society. I see this a lot in the health and fitness industry. It is a tactic where something is over promised and oftentimes under delivers.We are sold that we can precisely identify a “problem” and in turn easily “fix” it. This is because the truth to bouncing back from an injury or maintaining physical capabilities isn’t sexy."}
    />,
    <Article
        key={"blog30"}
        href={"how-buff-do-you-need-to-be-healthy"}
        src={Blog30}
        title={"How Buff Do You Need to Be Healthy?"}
        author={"Dr. Donald Mull, DC"}
        date={"Jan. 24, 2023"}
        previewText={"Having muscles is healthy. I would say that is a pretty accepted thought and not a lot of people would argue. But, do you need to be jacked like Arnold to reap the health benefits? No, absolutely not."}
    />,
    <Article
        key={"blog29"}
        href={"the-key-to-successful-training"}
        src={Blog29}
        title={"The Key to Successful Training"}
        author={"Dr. Donald Mull, DC"}
        date={"Jan. 9, 2023"}
        previewText={"In my opinion Training is a major pillar in healthcare. Training promotes long term health while staving off chronic illnesses such as heart disease, diabetes, stroke and even some cancers. Considering these are some of the leading causes of disability and healthcare costs - I’d say learning how to become and stay physically fit is CRUCIAL to healthcare."}
    />,
    <Article
        key={"blog27"}
        href={"training-with-kettlebells-the-basics"}
        src={Blog27}
        title={"Training with Kettlebells: The Basics"}
        author={"Dr. Donald Mull, DC"}
        date={"Jan. 4, 2023"}
        previewText={"The kettlebell's  unique shape allows versatility in a small space. Which makes it an awesome tool for strength, conditioning, and rehab."}
    />,
    <Article
        key={"blog26"}
        href={"most-meniscus-tears-dont-need-to-be-fixed"}
        src={Blog26}
        title={"Most Meniscus Tears Don’t Need to be Fixed"}
        author={"Dr. Donald Mull, DC"}
        date={"October 17, 2022"}
        previewText={"As a society we are often sold quick fixes and overpromised results when it comes to recurrent pain. If you are watching a sporting event on TV start paying attention at how many commercials that pop up on the screen from copper fit to medications that depict the transformative effect these products have on the pain and disability that you are experiencing."}
    />,
    <Article
        key={"blog25"}
        href={"joints-can-hurt-for-no-reason"}
        src={Blog25}
        title={"Joints Can Hurt for No Reason"}
        author={"Dr. Donald Mull, DC"}
        date={"October 3, 2022"}
        previewText={"I have become a BIG fan of Dr. Howard Luks and his content that he shares with his followers. He is an orthopedic surgeon who is incredibly well read and does a fantastic job sharing how current healthcare guidelines are falling short. \n"}
    />,
    <Article
        key={"blog24"}
        href={"my-two-cents-knee-pain-dont-just-stop-running"}
        src={Blog24}
        title={"My Two Cents: Knee Pain - Don’t Just Stop Running"}
        author={"Dr. Donald Mull, DC"}
        date={"September 27, 2022"}
        previewText={"If you enjoy running and you do it long enough, the occasional sensation of knee pain isn’t abnormal. For some this sensation can come with concern, worry and fear that running is inevitably the culprit."}
    />,
    <Article
        key={"blog23"}
        href={"5-reasons-you-should-resistance-train"}
        src={Blog23}
        title={"5 Reasons You Should Resistance Train"}
        author={"Dr. Donald Mull, DC"}
        date={"September 12, 2022"}
        previewText={"If all of the benefits of muscle strengthening through resistance training were packed into a pill - there would be a line out the door for people to buy it. However, there are no monetary vested interests or massive profits to be had on the scale of pharmaceuticals or charging insurances to treat illness to pump awareness."}
    />,
    <Article
        key={"blog22"}
        href={"herniated-disc-can-heal"}
        src={Blog22}
        title={"Herniated Disc CAN Heal"}
        author={"Dr. Donald Mull, DC"}
        date={"September 5, 2022"}
        previewText={"I was interested in writing about this topic after one of my patients was told their disc herniation would never go back to normal. This patient had very minor symptoms of occasional numbness and pain that got better with time (as most do) but was involved in a car crash so wanted to consult with a physician.\n"}
    />,
    <Article
        key={"blog21"}
        href={"foods-to-avoid-when-in-pain"}
        src={Blog21}
        title={"Foods to Avoid When in Pain"}
        author={"Dr. Donald Mull, DC"}
        date={"August 29, 2022"}
        previewText={"We often forget all of the factors that can influence pain. The easiest thing to do is to blame the last activity we have done physically. This leads to creating a bunch of physical limitations, causing inactivity. Doing this long enough leads your body to become less prepared for the physical activities you enjoy; it’s a vicious cycle. I see this all of the time. "}
    />,
    <Article
        key={"blog16b"}
        href={"can-i-exercise-with-low-back-pain"}
        src={Blog16}
        title={"Can I Exercise with Low Back Pain?"}
        author={"Dr. Donald Mull, DC"}
        date={"August 22, 2022"}
        previewText={"If I was forced to answer this simply, a Stone Cold Steve Austin “Can I get a Hell Yeah?” would swiftly follow. Since we are here for me to elaborate and share my thoughts on the topic, I’ll go a little deeper. Sit back and enjoy while I passionately nerd out. "}
    />,
    <Article
        key={"blog20"}
        href={"chiropractic-vs-physical-therapy-what-is-the-difference"}
        src={Blog20}
        title={"Chiropractic vs Physical Therapy: What is the Difference?"}
        author={"Dr. Donald Mull, DC"}
        date={"August 16, 2022"}
        previewText={"Having a clinic with both professions practicing together, we often get this question. It is understandable. From the outside looking in, these are sold as two completely different 'services'. "}
    />,
    <Article
        key={"blog19"}
        href={"insulin-resistance-and-diabetes-are-you-at-risk"}
        src={Blog19}
        title={"Insulin Resistance and Diabetes: Are you at risk?"}
        author={"Dr. Donald Mull, DC"}
        date={"August 8, 2022"}
        previewText={"Are you at risk for diabetes? You may be surprised by the answer after reading this!\n" +
            "\n" +
            "I wanted to touch on this topic because I read an article from an incredible Orthopedic Surgeon Dr. Howard Luks, MD that blew my mind and really challenged my viewpoint on how we screen for those at risk for Diabetes\n"}
    />,
    <Article
        key={"blog18"}
        href={"is-caffeine-bad-for-us"}
        src={Blog18}
        title={"Is Caffeine Bad For Us?"}
        author={"Dr. Donald Mull, DC"}
        date={"August 1, 2022"}
        previewText={"Is caffeine bad for us? Is there a certain amount of caffeine that is unhealthy? What does caffeine do? I feel like we get bashed nowadays for the degree of which we love our caffeinated beverages. "}
    />,
    <Article
        key={"blog17"}
        href={"why-working-out-is-good-for-you"}
        src={Blog17}
        title={"Why Working Out is Good For You"}
        author={"Dr. Donald Mull, DC"}
        date={"July 25, 2022"}
        previewText={"What are the benefits of being physically active? For the most part, I think it is pretty safe to say we inherently know that exercising and being active is healthy. But in my experience with helping people become more confident and more physically active with less limitations, it has become clear to me that most people are paralyzed with uncertainty around this topic. "}
    />,
    <Article
        key={"blog16"}
        href={"what-can-i-do-to-relieve-my-back-pain"}
        src={Blog16}
        title={"What Can I Do to Relieve my Back Pain?"}
        author={"Dr. Donald Mull, DC"}
        date={"July 18, 2022"}
        previewText={"Back pain is a very common condition and causes more disability than any other condition. So if you are feeling this, you are not alone and we see many people like yourself struggle with this condition. "}
    />,
    <Article
        key={"blog15"}
        href={"if-exercise-is-painful-should-i-stop"}
        src={Blog15}
        title={"If Exercise is Painful, Should I Stop?"}
        author={"Dr. Donald Mull, DC"}
        date={"July 11, 2022"}
        previewText={"Many people (especially when recovering from an injury or in pain) believe that if they have pain during an exercise, it is doing harm. However, this is not necessarily true. Pain is a poor indication of tissue damage so relying on the sensation of pain alone can be very misleading."}
    />,
    <Article
        key={"blog13"}
        href={"what-is-sciatica"}
        src={Blog13}
        title={"What is Sciatica?"}
        author={"Dr. Donald Mull, DC"}
        date={"January 22, 2020"}
        previewText={"Sciatica is one of the most common things we see in practice and probably one of the most misunderstood condition. This blog will help clear the air."}
    />,
    <Article
        key={"blog12"}
        href={"slouching-does-not-hurt-your-back"}
        src={Blog12}
        title={"Slouching Does NOT Hurt Your Back"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"Learn about postural myths and how this term is a scape goat for chronic pain. We are not fragile blocks stacked on top of each other like a game of Jenga waiting to be toppled over"}
    />,
    <Article
        key={"blog11"}
        href={"why-does-my-shoulder-keep-hurting"}
        src={Blog11}
        title={"Why does my shoulder keep hurting?"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"Shoulder injuries are extremely common, especially with those who are active, play recreational sports or perform repetitive motions at work or in the gym. Down load this PDF and take an active roll in your health recovery small changes create big differences."}
    />,
    <Article
        key={"blog10"}
        href={"5-tips-to-ditch-your-back-pain"}
        src={Blog10}
        title={"5 Tips to Ditch Your Back Pain"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"Upwards of 80 percent of the population will experience back pain at one point in their lifetime, so you are NOT alone. Read this blog and learn 5 easy tips that can change your narrative. Avoid being another statistic!"}
    />,
    <Article
        key={"blog6"}
        href={"what-to-expect-from-a-visit-to-kinetic-impact"}
        src={Blog6}
        title={"What to Expect from a Visit to Kinetic Impact"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"At Kinetic Impact our focus is on keeping our clients active and pain free. Our holistic approach combines physiotherapy and chiropractic techniques to heal injuries faster and optimize health and performance. Read this to learn more about how we can help you."}
    />,
    <Article
        key={"blog9"}
        href={"why-your-mid-back-is-killing-your-golf-game"}
        src={Blog9}
        title={"Why Your Mid-back is Killing Your Golf Game"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"The thoracic spine is simply the mid-back located in-between your shoulder blades. It is the portion of your back that connects to your ribs. There are the same amount of mid-back bones as there are pairs of ribs (which is 12). Having enough relative mobility in the mid-back is crucial in order to take stress off other parts of your body during the golf swing (especially the shoulders and low back)."}
    />,
    <Article
        key={"blog8"}
        href={"to-sit-or-to-stand-that-is-the-question"}
        src={Blog8}
        title={"To Sit or to Stand? That is the Question."}
        author={"Dr. Benjamin DeLuca, DC"}
        date={"September 4, 2019"}
        previewText={"I frequently get asked about sit to stand desks and what benefits they may or may not have. The answer might surprise you. Read on to learn more."}
    />,
    <Article
        key={"blog7"}
        href={"ditch-the-situps-your-back-will-thank-you"}
        src={Blog7}
        title={"Ditch the Situps: Your Back will Thank You"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"Whether you want to learn how to stop your low back pain or just want to know how to avoid back pain altogether, this one is for you. Read on to learn how to stop pissing off your low back AND build a ripped core."}
    />,
    <Article
        key={"blog6b"}
        href={"the-patient-experience"}
        src={Blog6}
        title={"The Patient Experience: What You Can Expect Working with Kinetic Impact"}
        author={"Kim Coutts, Kinetic Impact Patient"}
        date={"September 4, 2019"}
        previewText={"Curious to learn more about what to expect when working with us? Here you will hear it straight from the source! Kim shares her experience working with us along her recovery process."}
    />,
    <Article
        key={"blog5"}
        href={"are-dancers-athletes"}
        src={Blog5}
        title={"“Are Dancers Athletes?”"}
        author={"Dr. Donald Mull, DC"}
        date={"September 4, 2019"}
        previewText={"The simple answer to this question is F#$k yes they are! And for any dance athletes reading this you need to see yourself this way as well. It’s important you start embracing your inner athlete and realize how cross training, and therapeutic modalities may help prevent injury, increase performance, improve recovery, and add longevity to your career. It’s also equally important to seek out help and address any nagging discomforts in your body before they grow into bigger problems."}
    />,
    <Article
        key={"blog4"}
        href={"sticking-with-it"}
        src={Blog4}
        title={"Key Tips to Sticking with Your New Exercise Program"}
        author={"Sticking with exercise can be difficult and often times overwhelming. Here are some tips that you can implement to make sure you do it the right way."}
        date={"September 2, 2019"}
        previewText={"Learn about postural myths and how this term is a scape goat for chronic pain. We are not fragile blocks stacked on top of each other like a game of Jenga waiting to be toppled over"}
    />,
    <Article
        key={"blog3"}
        href={"tips-for-your-neck"}
        src={Blog3}
        title={"Tips for Your Neck Pain"}
        author={"Dr. Donald Mull, DC"}
        date={"August 29, 2019"}
        previewText={"The key to getting rid of your neck pain and staying out of pain is breaking the pain cycle early and often. But what does that even mean? Let’s get into it. Everyone has experienced pain at one point in their life and I am sure you have to. We often hear how everyone experiences this sensation differently, which is entirely true and is why it can be so complex."}
    />,
    <Article
        key={"blog2"}
        href={"tight-muscles"}
        src={Blog2}
        title={"Why Do My Muscles Feel Tight All the Time?"}
        author={"Dr. Donald Mull, DC"}
        date={"August 5, 2019"}
        previewText={"Ever curious as to why those upper traps, hamstrings or low back muscles feel tight? Why doesn’t the relaxed feeling from massages, foam rolling or lacrosse ball smashing last?"}
    />,
    <Article
        key={"blog1"}
        href={"four-quick-ways"}
        src={Blog1}
        title={"4 Ways to Quickly Relieve Tight Muscles (including detailed video examples)"}
        author={"Dr. Donald Mull, DC"}
        date={"August 5, 2019"}
        previewText={"Tightness is an interesting phenomenon that is quite different from person to person. One person may actually have a decrease in range of motion, the other may not. One person’s muscle feels like a rope, the other person’s feels like jello. All with the same complaint of “tightness”."}
    />
];

function ArticleList() {
    const [currentPage, setCurrentPage] = useState(1);
    const [currentArticles, setCurrentArticles] = useState(articles.slice(0, 5));

    function nextPage() {
        if(((currentPage+1)*5) < articles.length){
        setCurrentArticles(articles.slice(((currentPage+1)*5)-5, (currentPage+1)*5))
        setCurrentPage(currentPage+1)
        }
    }

    function previousPage() {
        if(currentPage!==1){
            setCurrentArticles(articles.slice(((currentPage-1)*5)-5, (currentPage-1)*5))
            setCurrentPage(currentPage-1)
        }
    }

    return (
        <>
            {currentArticles}
            <div className="flex items-center py-8">
                <a href="#"
                   className="h-10 w-20 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center " onClick={previousPage}>Previous</a>
                <a href="#"
                   className="h-10 w-10 bg-accent hover:bg-accent font-semibold text-white text-sm flex items-center justify-center pl-2" onClick={nextPage}>Next <i
                    className="fas fa-arrow-right ml-2"></i></a>
            </div>
        </>
    );
}



// class Articles extends Component {
//
//
//
//     constructor(props) {
//         super(props);
//         this.state = {
//             currentPageArticles: this.articles.slice(0, 5),
//             currentPage: 1
//         };
//     }
//
//     setCurrentPageArticles = val => {
//         this.setState({currentPageArticles: val.currentTarget.value});
//     }
//
//     nextPage(){
//         this.setCurrentPageArticles(this.articles.slice((this.state.currentPage*5)-1, (this.state.currentPage*5)))
//
//         // console.log(this.state.currentPageArticles)
//         // this.forceUpdate()
//         // this.state.currentPage = this.state.currentPage + 1;
//     }
//
//
//
//
//     render() {
//         return(
//             <>
//                 {this.state.currentPageArticles}
//                 <div className="flex items-center py-8">
//                     <a href="#"
//                        className="h-10 w-20 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center " >Previous</a>
//                     <a href="#"
//                        className="h-10 w-10 bg-accent hover:bg-accent font-semibold text-white text-sm flex items-center justify-center pl-2" onClick={this.nextPage}>Next <i
//                         className="fas fa-arrow-right ml-2"></i></a>
//                 </div>
//             </>
//         )
//     }
// }

function Blog(){
    return(
        <>
            <Helmet>
                <title>Kinetic Impact | Blog</title>
            </Helmet>
            <Nav/>
        <div className="container mx-auto flex flex-wrap justify-center py-6">


            <section className="w-full md:w-2/3 flex flex-col items-center px-3">
                <ArticleList/>




            </section>

        </div>
            <Footer/>
        </>
    )
}

export default Blog
